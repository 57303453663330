<template>
  <div class="page">
    <Navbar title="学校明细" :callback="back" type="SMP" />
    <Login ref="login" :callback="init" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="image in task.image" :key="image.path"
        ><img :src="image.path" class="img"
      /></van-swipe-item>
    </van-swipe>
    <van-row class="simple">
      <van-col span="24">
        <van-row>
          <van-col span="24" class="name"
            >{{ task.name }}
            <van-tag :color="COLOR_M" v-if="task.schoolType === 'GOV'"
              >公办</van-tag
            ><van-tag :color="COLOR_M" v-if="task.schoolType === 'CLV'"
              >民办</van-tag
            ></van-col
          >
        </van-row>
        <van-row class="label">
          <van-col span="24">
            <span
              class="item bgc-gray"
              v-for="lb in task.label"
              :key="lb.name"
              >{{ lb.name }}</span
            >
          </van-col>
        </van-row>
        <van-row>
          <van-col span="18" class="time fc-orange">
            时间：{{ task.startTime }} 到 {{ task.endTime }}
          </van-col>
          <van-col span="6" class="count">数量:{{ task.count }}人</van-col>
        </van-row>
        <div class="hr"></div>
        <van-row>
          <van-col span="12" class="price"
            >预付费用：<span class="number color-s2">0元</span></van-col
          >
          <van-col span="12" class="button">
            <van-button
              class="btn"
              icon="sign"
              :color="COLOR_S1"
              size="mini"
              @click="signSubmit"
              >立即报名</van-button
            >
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="price"
            >推荐奖励：<span class="number color-s2">100元</span></van-col
          >
          <van-col span="12" class="button">
            <van-button
              class="btn"
              icon="share-o"
              :color="COLOR_M"
              size="mini"
              @click="shareImage"
              >立即推荐</van-button
            >
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-tabs :active="activeName" :color="COLOR" :border="true" class="detail">
      <van-tab title="详情介绍" name="introduce">
        <div
          v-if="detail.introduce !== ''"
          v-html="task.detail[0].content"
        ></div>
        <div class="no-record" v-if="detail.introduce === ''">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image></div
      ></van-tab>
      <van-tab title="预约记录" name="record"
        ><div class="no-record">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Share ref="share" />
    <Tabbar mode="enroll" active="home" v-if="role !== 'SEL'" />
    <LogVisit ref="logVisit" module-type="ERO" module-label="ERO_DETAIL_INDEX" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Swipe, SwipeItem, Tabs, Tab, Sticky, Tag, Overlay, Popup, Icon, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Tabbar from '../common/Tabbar.vue'
import LogVisit from '../../common/LogVisit.vue'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Tabbar: Tabbar,
    LogVisit: LogVisit,
    Loading: Loading,
    Login: Login,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Sticky.name]: Sticky,
    [Tag.name]: Tag,
    [Overlay.name]: Overlay,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Image.name]: Image
  },
  data () {
    return {
      code: '',
      shareShow: false,
      wechatShow: false,
      loadingShow: false,
      role: 'CLT',
      activeName: 'school',
      taskCode: '',
      person: '',
      studentCode: '',
      task: {},
      detail: { introduce: '' }
    }
  },
  mounted: function () {
    var query = this.$route.query
    this.taskCode = query.taskCode
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.retrieveDetail()
      this.retrievePerson()
      var personCode = window.sessionStorage.getItem('member_person_chose')
      if (personCode !== null && personCode !== undefined && personCode !== '') {
        this.personCode = personCode
        window.sessionStorage.removeItem('member_person_chose')
        this.createOrder()
      }
      this.$refs.logVisit.createLog()
    },
    initShare () {
      var title = this.task.promoTitle
      var desc = this.task.promoDesc
      var link = 'https://moc.utopanet.com/enroll/detail?taskCode=' + this.taskCode

      if (this.role === 'SEL') {
        link = link + '&refereeType=SEL'
      }
      if (this.role === 'CLT') {
        link = link + '&refereeType=CLT'
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.initParam(title, desc, link, logo)
    },
    async retrieveDetail () {
      this.loadingShow = true
      var that = this
      var pd = { taskCode: that.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/enroll/taskAPC/retrieveTask', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.task = res.data
        var detail = res.data.detail
        if (detail.length > 0) {
          detail.forEach(element => {
            if (element.type === 'ITD') {
              that.detail.introduce = element.content
            }
          })
        }
        this.initShare()
      }
      this.loadingShow = false
    },
    signSubmit () {
      var vad = this.validateToken()
      if (vad) {
        this.$dialog.confirm({
          title: '提示',
          message: '确认立即报名吗？'
        })
          .then(() => {
            // on confirm
            var backKey = window.const.global.MEMBER_PERSON_MATCH_BACK
            window.sessionStorage.setItem(backKey, window.location.href)
            this.$router.push({ path: '/member/personMatch', query: { taskCode: this.taskCode, business: 'ERO' } })
          })
      }
    },
    async createOrder () {
      this.loadingShow = true
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
      var refereeType = window.sessionStorage.getItem(this.SESSION_REFEREE_TYPE)
      var site = window.sessionStorage.getItem(this.SESSION_SITE)
      var source = window.sessionStorage.getItem(this.SESSION_SOURCE)
      var pd = {
        taskCode: this.taskCode,
        personCode: this.personCode,
        operatorCode: operator,
        sellerCode: seller,
        refereeCode: referee,
        refereeType: refereeType,
        siteCode: site,
        source: source
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/enroll/order/createOrder', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示',
          message: '预约成功,请耐心等待'
        })
      } else {
        this.$dialog.alert({
          title: '提交失败',
          message: res.msg
        })
      }
      this.loadingShow = false
    },
    async retrievePerson () {
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token !== '' && token !== undefined && token !== null) {
        var user = window.sessionStorage.getItem(this.SESSION_USER)
        var pd = { userCode: user }
        const { data: res } = await this.$http.post(this.BMS_URL + '/data/person/retrieveUserPerson', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.person = res.data
        } else {
          this.$dialog.confirm({
            title: '提示',
            message: '个人信息未完善,是否立即完善'
          })
            .then(() => {
              // on confirm
              this.$router.push({ path: '/person/resumeEdit' })
            })
            .catch(() => {
              // on cancel
            })
        }
      }
    },
    updatePerson (code) {
      var vad = this.validateToken()
      if (vad) {
        this.$router.push({ path: '/person/resumeEdit' })
      }
    },
    validateToken () {
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token === null) {
        this.$router.push({ path: '/bind' })
        return false
      } else {
        return true
      }
    },
    shareImage () {
      this.$router.push({ path: '/enroll/shareImage', query: { taskCode: this.taskCode } })
    },
    back () {
      this.$router.push({ path: '/enroll/home' })
    }
  }
}
</script>
<style lang="less" scoped>
.top-img {
  color: #fff;
  width: 96%;
  margin: 10px 2% 0px 2%;
  text-align: center;
  .img {
    width: 100%;
    border-radius: 5px;
  }
}
.simple {
  width: 96%;
  margin: 5px 2%;
  padding-bottom: 10px;
  box-shadow: 0 0 9px 3px #eee;
  border-radius: 5px;
  text-align: left;
  .name {
    margin-top: 20px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .time {
    font-size: 12px;
    margin-top: 5px;
    padding-left: 20px;
  }
  .count {
    font-size: 12px;
    color: #8a8a8a;
    margin-top: 5px;
  }
  .label {
    margin: 5px 0px 0px 20px;
    font-size: 12px;
    .item {
      padding: 2px 5px;
      margin-right: 3px;
    }
  }
  .hr {
    width: 96%;
    border-bottom: 1px solid #eee;
    margin: 3px 2%;
  }
  .price {
    height: 35px;
    line-height: 35px;
    color: #aaa;
    text-align: left;
    padding-left: 20px;
    .number {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .button {
    height: 35px;
    line-height: 35px;
    .btn {
      width: 80%;
    }
  }
}

.advert {
  width: 100%;
}
.advert .img {
  width: 100%;
}
.detail {
  margin: 0px 5px 80px 5px;
}
.chapter {
  padding: 10px 10px;
}
.chapter .title {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.chapter .line {
  margin-top: 10px;
  vertical-align: 10px;
}
.chapter .imgs {
  color: #fff;
  width: 96%;
  margin: 5px 2% 0px 2%;
  text-align: center;
}
.chapter .imgs .img {
  width: 100%;
  border-radius: 10px;
}
.chapter .content {
  padding: 10px 0px;
  text-align: left;
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 80px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 50px;
  box-shadow: 0px 0px 5px #bbb;

  .button {
    display: flex;
    flex-flow: row;
    height: 50px;
    line-height: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    .btn {
      width: 90%;
      height: 30px;
      margin: 0px 1px;
      line-height: 30px;
      border: 1px solid #eee;
    }
  }
}
.shareImg {
  width: 90%;
}
</style>
